import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import Modal from '../../Components/modal';
import InputMask from 'react-input-mask';
import Api from '../../Services/Api';
import { toast } from 'react-toastify';
import { toastError } from '../../Util/funcions';

export default class Sellers extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modalOpen: false,
            requestingRegister: false,
            sellers: [],
            editUserId: null
        }
    }

    componentDidMount() {
        this.getData();
        console.log(this.props.match.params)
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ requestingRegister: true })
        var data = {};
        let form = new FormData(e.target);
        form.forEach((value, key) => { data[key] = value });
        try {
            if (this.state.editUserId) {
                await Api.put('/user/id/' + this.state.editUserId, data);
                this.getData();
                toast.success('Vendedor editado');
            } else {
                await Api.post(`/store/${this.props.match.params.id}/sellers`, data);
                this.getData();
                toast.success('Vendedor cadastrado');
            }
            this.setState({ requestingRegister: false, modalOpen: false });
        } catch (error) {
            this.setState({ requestingRegister: false })
            toastError(error);
        }
    }

    getData = async () => {
        try {
            let request = await Api.get(`/store/${this.props.match.params.id}/sellers`);
            console.log(request.data)
            this.setState({ sellers: request.data.sellers });
            console.log(request.data)
        } catch (error) {
            toastError(error);
        }
    }

    handleDeleteSeller = async (sellerId) => {
        if (window.confirm("Deseja excluir este vendedor?")) {
            try {
                await Api.delete('/seller/' + sellerId);
                this.getData();
                toast.success('Vendedor excluido');
            } catch (error) {
                return toastError(error);
            }
        }
    }

    openEditSellerModal = async (sellerId) => {
        this.setState({ editUserId: sellerId, modalOpen: true });
        try {
            let request = await Api.get(`/store/${this.props.match.params.id}/sellers/${sellerId}`);
            let form = document.forms['seller_form'];
            let seller = request.data.seller;
            form.elements["user_name"].value = seller.name;
            form.elements["email"].value = seller.email;
        } catch (error) {
            this.setState({ editUserId: null, modalOpen: false })
            toastError(error);
        }
    }


    render() {
        let openModal = () => {
            this.setState({ modalOpen: true });
        }
        let closeModal = () => {
            this.setState({ modalOpen: false, editUserId: null });
        }
        return (
            <>
                <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                    <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                        <FontAwesomeIcon icon={faBars} />
                    </button>
                    <button onClick={openModal} className="btn btn-primary btn-icon-split">
                        <span className="icon text-white-50">
                            <FontAwesomeIcon icon={faPlus} />
                        </span>
                        <span className="text">Cadastrar vendedor</span>
                    </button>
                </nav>
                <div className="container-fluid">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Nome</th>
                                <th scope="col">Total vendido</th>
                                <th scope="col">Email</th>
                                <th scope="col">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.sellers.map(seller => (
                                <tr key={seller.id}>
                                    <td>{seller.name}</td>
                                    <td>R$1300,00</td>
                                    <td>{seller.email}</td>
                                    <td>
                                        <button className="btn-neutral" onClick={() => { this.openEditSellerModal(seller.id) }}>
                                            <FontAwesomeIcon icon={faEdit} className="text-primary" />
                                        </button>
                                        <button className="btn-neutral" onClick={() => { this.handleDeleteSeller(seller.id) }}>
                                            <FontAwesomeIcon icon={faTrash} className="ml-1 text-primary" />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <Modal
                    isOpen={this.state.modalOpen}
                    onRequestClose={closeModal}
                    title={"Cadastrar vendedor"}>
                    <form onSubmit={this.handleSubmit} className="row" id="seller_form">
                        <div className="form-group col-md-6">
                            <label>Nome do responsável</label>
                            <input type="text" name="user_name" className="form-control" />
                        </div>
                        <div className="form-group col-md-6">
                            <label>Email</label>
                            <input type="email" name="email" className="form-control" disabled={this.state.editUserId} />
                        </div>
                        {
                            this.state.editUserId ?
                                null
                                :
                                <>
                                    <div className="form-group col-md-6">
                                        <label>Senha</label>
                                        <input type="password" name="password" className="form-control" />
                                    </div>
                                </>
                        }
                        <div className="col-12">

                            {
                                this.state.requestingRegister
                                    ?
                                    <span>Cadastrando...</span>
                                    :
                                    <button type="submit" className="btn btn-primary">Enviar</button>
                            }
                        </div>
                    </form>
                </Modal>
            </>
        )
    }
}


import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import Modal from '../../Components/modal';
import InputMask from 'react-input-mask';
import Axios from 'axios';
import Api from '../../Services/Api';
import { toast } from 'react-toastify';
import { toastError } from '../../Util/funcions';

export default class Sellers extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modalOpen: false,
            requestingAddress: false,
            requestingRegister: false,
            requestingEditData: false,
            sellers: [],
            editSellerId: null
        }
        this.streetNumberRef = React.createRef();
    }

    componentDidMount() {
        this.getData();
    }

    searchAddress = async (e) => {
        this.setState({ requestingAddress: true });
        let cep = e.target.value.replace(/^\D+/g, '');;
        try {
            let addressRequest = await Axios.get(`https://viacep.com.br/ws/${cep}/json/`);
            if (addressRequest.data.erro) {
                this.setState({ requestingAddress: false });
                return alert('Erro ao buscar endereço com o CEP digitado');
            }
            let form = document.forms['seller_form'];
            let address = addressRequest.data;
            form.elements["street"].value = address.logradouro;
            form.elements["city"].value = address.localidade;
            form.elements["state"].value = address.uf;
            form.elements["neighborhood"].value = address.bairro;
            this.streetNumberRef.current.focus();
            this.setState({ requestingAddress: false });
        } catch (error) {
            alert('Erro ao buscar endereço com o CEP digitado');
            this.setState({ requestingAddress: false });
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ requestingRegister: true })
        var data = {};
        let form = new FormData(e.target);
        form.forEach((value, key) => { data[key] = value });
        if (!this.state.editSellerId) {
            data.bypass_yapay = document.getElementById('bypass_yapay').checked
        }
        try {
            if (this.state.editSellerId) {
                await Api.put('/seller/id/' + this.state.editSellerId, data);
                toast.success('Seller editado');
            } else {
                await Api.post('/seller', data);
                toast.success('Seller cadastrado');
            }
            this.setState({ requestingRegister: false, modalOpen: false });
        } catch (error) {
            this.setState({ requestingRegister: false })
            toastError(error);
        }
    }

    getData = async () => {
        try {
            let request = await Api.get('/seller');
            this.setState({ sellers: request.data.sellers });
            console.log(request.data)
        } catch (error) {
            toastError(error);
        }
    }

    handleDeleteSeller = async (sellerId) => {
        if (window.confirm("Deseja excluir este vendedor?")) {
            try {
                await Api.delete('/seller/' + sellerId);
                this.getData();
                toast.success('Vendedor excluido');
            } catch (error) {
                return toastError(error);
            }
        }
    }

    openEditSellerModal = async (sellerId) => {
        this.setState({ editSellerId: sellerId, modalOpen: true });
        try {
            let request = await Api.get('/seller/id/' + sellerId);
            let form = document.forms['seller_form'];
            let seller = request.data.seller;
            form.elements["name"].value = seller.name;
            form.elements["email"].value = seller.email;
            form.elements["trade_name"].value = seller.trade_name;
            form.elements["company_name"].value = seller.company_name;
            form.elements["cpf"].value = seller.cpf;
            form.elements["cnpj"].value = seller.cnpj;
            form.elements["percentage"].value = seller.percentage;
            form.elements["phone_ddd"].value = seller.phone_ddd;
            form.elements["phone_number"].value = seller.phone_number;
            form.elements["postal_code"].value = seller.postal_code;
            form.elements["street"].value = seller.street;
            form.elements["street_number"].value = seller.street_number;
            form.elements["city"].value = seller.city;
            form.elements["neighborhood"].value = seller.neighborhood;
            form.elements["state"].value = seller.state;
        } catch (error) {
            this.setState({ editSellerId: null, modalOpen: false })
            toastError(error);
        }
    }


    render() {
        let openModal = () => {
            this.setState({ modalOpen: true });
        }
        let closeModal = () => {
            this.setState({ modalOpen: false, editSellerId: null });
        }
        return (
            <>
                <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                    <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                        <FontAwesomeIcon icon={faBars} />
                    </button>
                    <button onClick={openModal} className="btn btn-primary btn-icon-split">
                        <span className="icon text-white-50">
                            <FontAwesomeIcon icon={faPlus} />
                        </span>
                        <span className="text">Cadastrar vendedor</span>
                    </button>
                </nav>
                <div className="container-fluid">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Nome</th>
                                <th scope="col">Total transacionado</th>
                                <th scope="col">CNPJ</th>
                                <th scope="col">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.sellers.map(seller => (
                                <tr key={seller.id}>
                                    <td>{seller.company_name}</td>
                                    <td>R$1300,00</td>
                                    <td>{seller.cnpj}</td>
                                    <td>
                                        <button className="btn-neutral" onClick={() => { this.openEditSellerModal(seller.id) }}>
                                            <FontAwesomeIcon icon={faEdit} className="text-primary" />
                                        </button>
                                        <button className="btn-neutral" onClick={() => { this.handleDeleteSeller(seller.id) }}>
                                            <FontAwesomeIcon icon={faTrash} className="ml-1 text-primary" />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <Modal
                    isOpen={this.state.modalOpen}
                    onRequestClose={closeModal}
                    title={"Cadastrar vendedor"}>
                    <form onSubmit={this.handleSubmit} className="row" id="seller_form">
                        <div className="form-group col-md-6">
                            <label>Nome do responsável</label>
                            <input type="text" name="name" className="form-control" />
                        </div>
                        <div className="form-group col-md-6">
                            <label>Email</label>
                            <input type="email" name="email" className="form-control" disabled={this.state.editSellerId} />
                        </div>
                        <div className="form-group col-md-6">
                            <label>Nome fantasia</label>
                            <input type="text" name="trade_name" className="form-control" />
                        </div>
                        <div className="form-group col-md-6">
                            <label>Razão social</label>
                            <input type="text" name="company_name" className="form-control" />
                        </div>
                        <div className="form-group col-md-6">
                            <label>CPF</label>
                            <InputMask mask="999.999.999-99">
                                {(inputProps) => <input {...inputProps} type="text" name="cpf" className="form-control" />}
                            </InputMask>
                        </div>
                        <div className="form-group col-md-6">
                            <label>CNPJ</label>
                            <InputMask mask="99.999.999/9999-99">
                                {(inputProps) => <input {...inputProps} type="text" name="cnpj" className="form-control" />}
                            </InputMask>
                        </div>
                        <div className="form-group col-md-2">
                            <label>DDD</label>
                            <input type="number" name="phone_ddd" maxLength={2} className="form-control" />
                        </div>
                        <div className="form-group col-md-10">
                            <label>Número</label>
                            <InputMask mask="99999-9999">
                                {(inputProps) => <input {...inputProps} type="text" name="phone_number" className="form-control" />}
                            </InputMask>
                        </div>

                        <div className="form-group col-md-6">
                            <label>CEP</label>
                            <InputMask mask="99999-999" onBlur={this.searchAddress}>
                                {(inputProps) => <input {...inputProps} type="text" name="postal_code" className="form-control" />}
                            </InputMask>
                        </div>
                        <div className="form-group col-md-6">
                            <label>Cidade</label>
                            <input type="text" name="city" className="form-control" disabled={this.state.requestingAddress} />
                        </div>
                        <div className="form-group col-md-9">
                            <label>Rua</label>
                            <input type="text" name="street" className="form-control" disabled={this.state.requestingAddress} />
                        </div>
                        <div className="form-group col-md-3">
                            <label>Número</label>
                            <input type="text" name="street_number" className="form-control" ref={this.streetNumberRef} />
                        </div>
                        <div className="form-group col-md-6">
                            <label>Estado</label>
                            <input type="text" name="state" maxLength={2} className="form-control" disabled={this.state.requestingAddress} />
                        </div>
                        <div className="form-group col-md-6">
                            <label>Bairro</label>
                            <input type="text" name="neighborhood" className="form-control" disabled={this.state.requestingAddress} />
                        </div>
                        {
                            this.state.editSellerId ?
                                null
                                :
                                <>
                                    <div className="form-group col-md-6">
                                        <label>Senha</label>
                                        <input type="password" name="password" className="form-control" />
                                    </div>
                                    <div className="col-6 d-flex align-items-center pl-5">
                                        <input type="checkbox" className="form-check-input" id="bypass_yapay" />
                                        <label className="form-check-label" htmlFor="bypass_yapay">Não cadastrar conta Yapay</label>
                                    </div>
                                </>
                        }
                        <div className="form-group col-md-6">
                            <label>Porcentagem</label>
                            <input type="number" step="any" name="percentage" className="form-control" />
                        </div>
                        <div className="col-12">

                            {
                                this.state.requestingRegister
                                    ?
                                    <span>Cadastrando...</span>
                                    :
                                    <button type="submit" className="btn btn-primary">Enviar</button>
                            }
                        </div>
                    </form>
                </Modal>
            </>
        )
    }
}


import axios from 'axios';

let headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
}

const Api = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? 'https://api.emepag.com.br/v1' : 'http://localhost:1338/v1',
    headers
});

Api.interceptors.request.use(async config => {
    const token = localStorage.getItem('user_token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});


Api.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response && error.response.data && error.response.data.message === 'token-invalid-or-expired') {
        localStorage.clear();
        return window.location.replace('/login?expired=true');
    }
    return Promise.reject(error);
});

export default Api;
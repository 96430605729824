import React from 'react';
import ReactModal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        borderWidth: 0.5,
        boxShadow: "0 0 5px",
        borderRadius: 10,
        maxHeight: '90%'
    }
};

ReactModal.setAppElement('#root');

export default function Modal(props) {
    return (
        <div>
            <ReactModal
                {...props}
                style={customStyles}
            >
                <div className="row d-flex justify-content-between align-items-center">
                    <h3 className="ml-3">{props.title}</h3>
                    <button onClick={props.onRequestClose} className="mr-2" style={{ border: 'none' }}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>
                <hr />
                {props.children}
            </ReactModal>
        </div>
    );
}
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faPlus, faEdit, faTrash, faStoreAlt, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import Modal from '../../Components/modal';
import InputMask from 'react-input-mask';
import Api from '../../Services/Api';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { toastError } from '../../Util/funcions';
import { Link } from 'react-router-dom';

export default class Sellers extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modalOpen: false,
            userModalOpen: false,
            storesModalOpen: false,
            editStoreModalOpen: false,
            requestingRegister: false,
            requestingEditData: false,
            companies: [],
            stores: [],
            editCompanyId: null,
            editUserId: null,
            editStoreId: null,
            companyUsers: []
        };
        this.streetNumberRef = React.createRef();
    }

    componentDidMount() {
        this.getData();
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ requestingRegister: true })
        var data = {};
        let form = new FormData(e.target);
        form.forEach((value, key) => { data[key] = value });
        try {
            if (this.state.editCompanyId) {
                await Api.put('/company/id/' + this.state.editCompanyId, data);
                toast.success('Empresa atualziada');
                this.getData();
            } else {
                await Api.post('/company', data);
                toast.success('Empresa cadastrada');
                this.getData();
            }
            this.setState({ requestingRegister: false, modalOpen: false });
        } catch (error) {
            this.setState({ requestingRegister: false })
            toastError(error);
        }
    }

    handleUserSubmit = async (e) => {
        e.preventDefault();
        this.setState({ requestingRegister: true })
        var data = {};
        let form = new FormData(e.target);
        form.forEach((value, key) => { data[key] = value });
        try {
            if (this.state.editUserId) {
                await Api.put('/company/admins/' + this.state.editUserId, data);
                toast.success('Usuário atualziado');
                this.getUsersData(this.state.editCompanyId);
            } else {
                await Api.post(`/company/${this.state.editCompanyId}/admins`, data);
                toast.success('Usuário cadastrado');
                this.getUsersData(this.state.editCompanyId);
            }
            this.setState({ requestingRegister: false, userModalOpen: false });
        } catch (error) {
            this.setState({ requestingRegister: false })
            toastError(error);
        }
    }

    handleStoreSubmit = async (e) => {
        e.preventDefault();
        this.setState({ requestingRegister: true })
        var data = {};
        let form = new FormData(e.target);
        form.forEach((value, key) => { data[key] = value });
        if (!this.state.editStoreId) {
            data.bypass_yapay = document.getElementById('bypass_yapay').checked
        }
        try {
            if (this.state.editStoreId) {
                await Api.put('/store/id/' + this.state.editStoreId, data);
                this.getStoresData(this.state.editCompanyId);
                this.setState({ editStoreModalOpen: false, editStoreId: null });
                toast.success('Loja editada');
            } else {
                data.company_id = this.state.editCompanyId;
                await Api.post('/store', data);
                this.setState({ editStoreModalOpen: false });
                this.getStoresData(this.state.editCompanyId);
                toast.success('Loja cadastrada');
            }
            this.setState({ requestingRegister: false, modalOpen: false });
        } catch (error) {
            this.setState({ requestingRegister: false })
            toastError(error);
        }
    }

    getData = async () => {
        try {
            let request = await Api.get('/company');
            this.setState({ companies: request.data.companies });
            console.log(request.data)
        } catch (error) {
            toastError(error);
        }
    }

    getUsersData = async (companyId) => {
        let usersRequest = await Api.get(`/company/${companyId}/admins`);
        this.setState({ companyUsers: usersRequest.data.users });
    }

    getStoresData = async (companyId) => {
        let response = await Api.get(`/company/${companyId}/stores`);
        this.setState({ stores: response.data.stores });
    }

    handleDeleteCompany = async (companyId) => {
        if (window.confirm("Deseja excluir esta empresa?")) {
            try {
                await Api.delete('/company/' + companyId);
                this.getData();
                toast.success('Empresa excluida');
            } catch (error) {
                return toastError(error);
            }
        }
    }

    handleDeleteStore = async (storeId) => {
        if (window.confirm("Deseja excluir esta loja?")) {
            try {
                await Api.delete('/store/' + storeId);
                this.getData();
                toast.success('Loja excluida');
            } catch (error) {
                return toastError(error);
            }
        }
    }

    openEditCompanyModal = async (companyId) => {
        this.setState({ editCompanyId: companyId, modalOpen: true });
        try {
            let request = await Api.get('/company/id/' + companyId);
            this.getUsersData(companyId);
            let form = document.forms['company_form'];
            let company = request.data.company;
            form.elements["name"].value = company.name;
            form.elements["cnpj"].value = company.cnpj;
            form.elements["percentage"].value = company.percentage;
        } catch (error) {
            this.setState({ editCompanyId: null, modalOpen: false })
            toastError(error);
        }
    }

    openEditStoreModal = async (storeId) => {
        this.setState({ editStoreId: storeId, editStoreModalOpen: true });
        try {
            let request = await Api.get('/store/id/' + storeId);
            let form = document.forms['store_form'];
            let store = request.data.store;
            form.elements["name"].value = store.name;
            form.elements["email"].value = store.email;
            form.elements["trade_name"].value = store.trade_name;
            form.elements["company_name"].value = store.company_name;
            form.elements["cpf"].value = store.cpf;
            form.elements["cnpj"].value = store.cnpj;
            form.elements["percentage"].value = store.percentage;
            form.elements["phone_ddd"].value = store.phone_ddd;
            form.elements["phone_number"].value = store.phone_number;
            form.elements["postal_code"].value = store.postal_code;
            form.elements["street"].value = store.street;
            form.elements["street_number"].value = store.street_number;
            form.elements["city"].value = store.city;
            form.elements["neighborhood"].value = store.neighborhood;
            form.elements["state"].value = store.state;
        } catch (error) {
            this.setState({ editStoreId: null, editStoreModalOpen: false })
            toastError(error);
        }
    }

    openStoresModal = async (companyId) => {
        this.setState({ editCompanyId: companyId, storesModalOpen: true });
        try {
            let response = await Api.get('/company/' + companyId + '/stores');
            console.log(response.data)
            this.setState({ stores: response.data.stores })
        } catch (error) {
            this.setState({ editCompanyId: null, storesModalOpen: false })
            toastError(error);
        }
    }



    openEditUserModal = async (userId) => {
        this.setState({ editUserId: userId, userModalOpen: true });
        try {
            let request = await Api.get('/company/admins/' + userId);
            console.log(request.data)
            let form = document.forms['company_user_form'];
            console.log(form);
            let user = request.data.user;
            form.elements["user_name"].value = user.name;
            form.elements["email"].value = user.email;
        } catch (error) {
            console.log(error);
            this.setState({ editUserId: null, userModalOpen: false })
            toastError(error);
        }
    }

    searchAddress = async (e) => {
        this.setState({ requestingAddress: true });
        let cep = e.target.value.replace(/^\D+/g, '');;
        try {
            let addressRequest = await Axios.get(`https://viacep.com.br/ws/${cep}/json/`);
            if (addressRequest.data.erro) {
                this.setState({ requestingAddress: false });
                return alert('Erro ao buscar endereço com o CEP digitado');
            }
            let form = document.forms['store_form'];
            let address = addressRequest.data;
            form.elements["street"].value = address.logradouro;
            form.elements["city"].value = address.localidade;
            form.elements["state"].value = address.uf;
            form.elements["neighborhood"].value = address.bairro;
            this.streetNumberRef.current.focus();
            this.setState({ requestingAddress: false });
        } catch (error) {
            alert('Erro ao buscar endereço com o CEP digitado');
            this.setState({ requestingAddress: false });
        }
    }


    render() {
        let openModal = () => {
            this.setState({ modalOpen: true, editCompanyId: null });
        }
        let closeModal = () => {
            this.setState({ modalOpen: false, editCompanyId: null });
        }
        let openUserModal = () => {
            this.setState({ userModalOpen: true });
        }
        let closeUserModal = () => {
            this.setState({ userModalOpen: false, editUserId: null });
        }
        let openEditStoreModal = () => {
            this.setState({ editStoreModalOpen: true });
        }
        let closeStoresModal = () => {
            this.setState({ storesModalOpen: false });
        }
        let closeEditStoreModal = () => {
            this.setState({ editStoreModalOpen: false, editStoreId: null });
        }
        return (
            <>
                <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                    <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                        <FontAwesomeIcon icon={faBars} />
                    </button>
                    <button onClick={openModal} className="btn btn-primary btn-icon-split">
                        <span className="icon text-white-50">
                            <FontAwesomeIcon icon={faPlus} />
                        </span>
                        <span className="text">Cadastrar empresa</span>
                    </button>
                </nav>
                <div className="container-fluid">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Nome</th>
                                <th scope="col">CNPJ</th>
                                <th scope="col">Total transacionado</th>
                                <th scope="col">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.companies.map(company => (
                                <tr key={company.id}>
                                    <td>{company.name}</td>
                                    <td>{company.cnpj}</td>
                                    <td>R$1300,00</td>
                                    <td>
                                        <button className="btn-neutral" onClick={() => { this.openEditCompanyModal(company.id) }}>
                                            <FontAwesomeIcon icon={faEdit} className="text-primary" />
                                        </button>
                                        <button className="btn-neutral" onClick={() => { this.openStoresModal(company.id) }}>
                                            <FontAwesomeIcon icon={faStoreAlt} className="text-primary" />
                                        </button>
                                        <button className="btn-neutral" onClick={() => { this.handleDeleteCompany(company.id) }}>
                                            <FontAwesomeIcon icon={faTrash} className="ml-1 text-primary" />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <Modal
                    isOpen={this.state.modalOpen}
                    onRequestClose={closeModal}
                    title={"Cadastrar/Editar Empresa"}>
                    <form onSubmit={this.handleSubmit} className="row" id="company_form">
                        <div className="form-group col-md-6">
                            <label>Nome da empresa</label>
                            <input type="text" name="name" className="form-control" />
                        </div>
                        <div className="form-group col-md-6">
                            <label>CNPJ</label>
                            <InputMask mask="99.999.999/9999-99">
                                {(inputProps) => <input {...inputProps} type="text" name="cnpj" className="form-control" />}
                            </InputMask>
                        </div>
                        <div className="form-group col-md-6">
                            <label>Porcentagem</label>
                            <input type="number" step="any" name="percentage" className="form-control" />
                        </div>
                        {
                            this.state.editCompanyId ?
                                <>
                                    <hr />
                                    <div className="col-12 my-4 d-flex flex-row align-items-center">
                                        <h5 className="mt-1 mr-4">Usuários</h5>
                                        <button onClick={e => { e.preventDefault(); openUserModal(); }} className="btn btn-primary btn-icon-split">
                                            <span className="icon text-white-50">
                                                <FontAwesomeIcon icon={faPlus} />
                                            </span>
                                            <span className="text">Cadastrar usuário</span>
                                        </button>
                                    </div>
                                    <div className="col-12">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Nome</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Ativo</th>
                                                    <th scope="col">Ações</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.companyUsers.map(user => (
                                                    <tr key={user.id}>
                                                        <td>{user.name}</td>
                                                        <td>{user.email}</td>
                                                        <td>{user.active ? 'Sim' : 'Não'}</td>
                                                        <td>
                                                            <button className="btn-neutral" onClick={(e) => { e.preventDefault(); this.openEditUserModal(user.id) }}>
                                                                <FontAwesomeIcon icon={faEdit} className="text-primary" />
                                                            </button>
                                                            <button className="btn-neutral" onClick={(e) => { e.preventDefault(); this.handleDeleteUser(user.id) }}>
                                                                <FontAwesomeIcon icon={faTrash} className="ml-1 text-primary" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="col-12">
                                        <hr />
                                        <h5>Dados de responsável da empresa</h5>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>Nome do responsável</label>
                                        <input type="text" name="user_name" className="form-control" />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>Email do responsável</label>
                                        <input type="text" name="email" className="form-control" />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>Senha</label>
                                        <input type="password" name="password" className="form-control" />
                                    </div>
                                </>
                        }
                        <div className="col-12">

                            {
                                this.state.requestingRegister
                                    ?
                                    <span>Cadastrando...</span>
                                    :
                                    <button type="submit" className="btn btn-primary">Enviar</button>
                            }
                        </div>
                    </form>
                </Modal>
                <Modal
                    isOpen={this.state.userModalOpen}
                    onRequestClose={closeUserModal}
                    title={"Cadastrar/Editar usuário"}>
                    <form onSubmit={this.handleUserSubmit} className="row" id="company_user_form">
                        <div className="form-group col-md-6">
                            <label>Nome do responsável</label>
                            <input type="text" name="user_name" className="form-control" />
                        </div>
                        <div className="form-group col-md-6">
                            <label>Email do responsável</label>
                            <input type="text" name="email" className="form-control" />
                        </div>
                        {
                            !this.state.editUserId ?
                                <div className="form-group col-md-6">
                                    <label>Senha</label>
                                    <input type="password" name="password" className="form-control" />
                                </div>
                                :
                                null
                        }
                        <div className="col-12">

                            {
                                this.state.requestingRegister
                                    ?
                                    <span>Cadastrando...</span>
                                    :
                                    <button type="submit" className="btn btn-primary">Enviar</button>
                            }
                        </div>
                    </form>
                </Modal>
                <Modal
                    isOpen={this.state.storesModalOpen}
                    onRequestClose={closeStoresModal}
                    title={"Cadastrar/Editar lojas"}>
                    <>
                        <div className="col-12 my-4 d-flex flex-row align-items-center">
                            <h5 className="mt-1 mr-4">Lojas</h5>
                            <button onClick={e => { e.preventDefault(); openEditStoreModal(); }} className="btn btn-primary btn-icon-split">
                                <span className="icon text-white-50">
                                    <FontAwesomeIcon icon={faPlus} />
                                </span>
                                <span className="text">Cadastrar loja</span>
                            </button>
                        </div>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Nome</th>
                                    <th scope="col">Total transacionado</th>
                                    <th scope="col">CNPJ</th>
                                    <th scope="col">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.stores.map(store => (
                                    <tr key={store.id}>
                                        <td>{store.trade_name}</td>
                                        <td>R$1300,00</td>
                                        <td>{store.cnpj}</td>
                                        <td>
                                            <button className="btn-neutral" onClick={() => { this.openEditStoreModal(store.id) }}>
                                                <FontAwesomeIcon icon={faEdit} className="text-primary" />
                                            </button>
                                            <Link className="btn-neutral" to={`/app/stores/${store.id}/users`}>
                                                <FontAwesomeIcon icon={faUserAlt} className="text-primary" />
                                            </Link>
                                            <button className="btn-neutral" onClick={() => { this.handleDeleteStore(store.id) }}>
                                                <FontAwesomeIcon icon={faTrash} className="ml-1 text-primary" />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </>
                </Modal>
                <Modal
                    isOpen={this.state.editStoreModalOpen}
                    onRequestClose={closeEditStoreModal}
                    title={"Cadastrar/Editar lojas"}>
                    <form onSubmit={this.handleStoreSubmit} className="row" id="store_form">
                        <div className="form-group col-md-6">
                            <label>Nome do responsável</label>
                            <input type="text" name="name" className="form-control" />
                        </div>
                        <div className="form-group col-md-6">
                            <label>Email</label>
                            <input type="email" name="email" className="form-control" disabled={this.state.editStoreId} />
                        </div>
                        <div className="form-group col-md-6">
                            <label>Nome fantasia</label>
                            <input type="text" name="trade_name" className="form-control" />
                        </div>
                        <div className="form-group col-md-6">
                            <label>Razão social</label>
                            <input type="text" name="company_name" className="form-control" />
                        </div>
                        <div className="form-group col-md-6">
                            <label>CPF</label>
                            <InputMask mask="999.999.999-99">
                                {(inputProps) => <input {...inputProps} type="text" name="cpf" className="form-control" />}
                            </InputMask>
                        </div>
                        <div className="form-group col-md-6">
                            <label>CNPJ</label>
                            <InputMask mask="99.999.999/9999-99">
                                {(inputProps) => <input {...inputProps} type="text" name="cnpj" className="form-control" />}
                            </InputMask>
                        </div>
                        <div className="form-group col-md-2">
                            <label>DDD</label>
                            <input type="number" name="phone_ddd" maxLength={2} className="form-control" />
                        </div>
                        <div className="form-group col-md-10">
                            <label>Número</label>
                            <InputMask mask="99999-9999">
                                {(inputProps) => <input {...inputProps} type="text" name="phone_number" className="form-control" />}
                            </InputMask>
                        </div>

                        <div className="form-group col-md-6">
                            <label>CEP</label>
                            <InputMask mask="99999-999" onBlur={this.searchAddress}>
                                {(inputProps) => <input {...inputProps} type="text" name="postal_code" className="form-control" />}
                            </InputMask>
                        </div>
                        <div className="form-group col-md-6">
                            <label>Cidade</label>
                            <input type="text" name="city" className="form-control" disabled={this.state.requestingAddress} />
                        </div>
                        <div className="form-group col-md-9">
                            <label>Rua</label>
                            <input type="text" name="street" className="form-control" disabled={this.state.requestingAddress} />
                        </div>
                        <div className="form-group col-md-3">
                            <label>Número</label>
                            <input type="text" name="street_number" className="form-control" ref={this.streetNumberRef} />
                        </div>
                        <div className="form-group col-md-6">
                            <label>Estado</label>
                            <input type="text" name="state" maxLength={2} className="form-control" disabled={this.state.requestingAddress} />
                        </div>
                        <div className="form-group col-md-6">
                            <label>Bairro</label>
                            <input type="text" name="neighborhood" className="form-control" disabled={this.state.requestingAddress} />
                        </div>
                        {
                            this.state.editStoreId ?
                                null
                                :
                                <>
                                    <div className="form-group col-md-6">
                                        <label>Senha</label>
                                        <input type="password" name="password" className="form-control" />
                                    </div>
                                    <div className="col-6 d-flex align-items-center pl-5">
                                        <input type="checkbox" className="form-check-input" id="bypass_yapay" />
                                        <label className="form-check-label" htmlFor="bypass_yapay">Não cadastrar conta Yapay</label>
                                    </div>
                                </>
                        }
                        <div className="form-group col-md-6">
                            <label>Porcentagem</label>
                            <input type="number" step="any" name="percentage" className="form-control" />
                        </div>
                        <div className="col-12">

                            {
                                this.state.requestingRegister
                                    ?
                                    <span>Cadastrando...</span>
                                    :
                                    <button type="submit" className="btn btn-primary">Enviar</button>
                            }
                        </div>
                    </form>
                </Modal>
            </>
        )
    }
}


import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { isAuthenticated } from "./Services/Auth";
import { ToastContainer } from 'react-toastify';

import Login from './Views/Login';
import Sidebar from './Components/sidebar';
import Sellers from './Views/Sellers';
import Companies from './Views/Companies';
import StoreUsers from './Views/StoreUsers';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
          <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
        )
    }
  />
);

const LoginRoute = () => {
  return isAuthenticated() ? <Redirect to={"/app"} /> : <Login />;
};

const AppRoutes = () => (
  <div id="wrapper">
    <Sidebar />
    <div id="content-wrapper" className="d-flex flex-column">
      <div id="content">
        <Switch>
          <PrivateRoute exact path="/app" component={Sellers} />
          <PrivateRoute exact path="/app/sellers" component={Sellers} />
          <PrivateRoute exact path="/app/stores/:id/users" component={StoreUsers} />
          <PrivateRoute exact path="/app/companies" component={Companies} />
          <Route path="/app/*" component={() => <h1>Page not found</h1>} />
        </Switch >
      </div>
    </div>
    <ToastContainer />
  </div>
)

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/login" component={LoginRoute} />
      <Route path="/cadastro" component={() => <h1>SignUp</h1>} />
      <PrivateRoute path="/app" component={AppRoutes} />
      <PrivateRoute exact path="/" component={() => (<Redirect to={{ pathname: "/app" }} />)} />
      <Route path="*" component={() => <h1>Page not found</h1>} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
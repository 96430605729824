import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faUser, faExternalLinkAlt, faBuilding } from '@fortawesome/free-solid-svg-icons';

export default class Sidebar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            currentPage: 'sellers'
        }
    }

    logout = () => {
        localStorage.clear();
        window.location.replace('/login');
    }

    render() {
        return (
            <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

                <a className="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
                    <div className="sidebar-brand-icon rotate-n-15">
                        <i className="fas fa-laugh-wink"></i>
                    </div>
                    <div className="sidebar-brand-text mx-3">EMEPAG</div>
                </a>

                <hr className="sidebar-divider my-0" />

                <li className={`nav-item ${this.state.currentPage === 'companies' ? 'active' : ''}`}>
                    <Link to={"/app/companies"} className="nav-link" onClick={() => { this.setState({ currentPage: 'companies' }) }}>
                        <FontAwesomeIcon icon={faBuilding} className="mr-2" />
                        <span>Empresas</span>
                    </Link>
                </li>

                <li className={`nav-item ${this.state.currentPage === 'users' ? 'active' : ''}`}>
                    <Link to={"/app/users"} className="nav-link" onClick={() => { this.setState({ currentPage: 'users' }) }}>
                        <FontAwesomeIcon icon={faUser} className="mr-2" />
                        <span>Usuários</span>
                    </Link>
                </li>

                <li className={`nav-item`}>
                    <button className="nav-link btn-neutral" onClick={this.logout}>
                        <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-2" />
                        <span>Sair</span>
                    </button>
                </li>
            </ul>
        )
    }
}
